<template>
  <div class="data-table">
    <BaseTable :data="displayData">
      <BaseElTableColumn label="消費紀錄編號" prop="serial" align="center" />
      <BaseElTableColumn label="來源" prop="origin" align="center" />
      <BaseElTableColumn v-if="options.showBranch" label="門市" prop="branch" align="center" />
      <BaseElTableColumn v-if="enableColumns.includes('shopName')" label="分店" prop="shopName" align="center" />
      <BaseElTableColumn label="會員手機號碼" prop="phone" align="center" />
      <BaseElTableColumn label="認列金額" prop="amount" align="center" />
      <BaseElTableColumn label="實際交易金額" prop="sellAmount" align="center" />
      <BaseElTableColumn label="交易時間" prop="time" align="center" width="120px" />
      <BaseElTableColumn label="是否做廢" prop="isInvalid" align="center">
        <template slot-scope="scope">
          <Tag :type="scope.row.isInvalid ? 'error' : 'info'">{{ scope.row.isInvalid ? '是' : '否' }}</Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="訂單狀態" prop="status" align="center" width="120px">
        <template slot-scope="scope">
          <Tag :type="memberExternalTransactionStatus(scope.row.status,'type')">{{ memberExternalTransactionStatus(scope.row.status, 'label') }}</Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            hideDelete
            editBtn="檢視"
            @edit="onRowEdit(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <ExternalTransactionRecordDetail
      v-if="show.detail"
      :record="selectRow"
      @close="show.detail = false"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import ExternalTransactionRecordDetail from './ExternalTransactionRecordDetail.vue'
import BaseTable from '@/components/BaseTable.vue'
import { get, map, find } from 'lodash'
import { formatDate } from '@/utils/date'
import { originsConfig, memberExternalTransactionStatusConfig } from '@/config/externalTransaction'
import { localeString } from '@/utils/helper'

export default defineComponent({
  name: 'ExternalTransactionRecordTable',
  components: { BaseTable, ExternalTransactionRecordDetail },
  props: {
    tableData: { type: Array, defalt: () => [] },
    enableColumns: { type: Array, default: () => [] },
    externalData: { type: Object, default: () => ({}) },
    options: { type: Object, default: () => ({}) },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const selectRow = ref(null)
    const show = reactive({
      detail: false,
    })
    const modal = reactive({
      delete: false,
    })

    const displayData = computed(() => {
      return map(props.tableData, (item) => {
        return {
          id: item.id,
          serial: item.transactionId,
          origin: get(originsConfig, `${item.source}.label`) || item.source,
          phone: item.customerPhone,
          amount: localeString(item.amount),
          sellAmount: localeString(item.sellAmount),
          time: formatDate(item.transactionTime),
          createdAt: formatDate(item.createdAt),
          isInvalid: item.isInvalid,
          transactionId: item.transactionId,
          items: item.items,
          status: item.status,
          shopName: get(find(get(props.externalData, 'shopList'), { id: item.ShopId }), 'name'),
          branch: get(item, 'Branch.name', '-'),
          shopId: item.ShopId,
        }
      })
    })
    const memberExternalTransactionStatus = (key, attr) => {
      return get(memberExternalTransactionStatusConfig[key], attr)
    }
    const onRowEdit = (row) => {
      selectRow.value = row
      show.detail = true
    }

    return {
      modal,
      displayData,
      onRowEdit,
      show,
      selectRow,
      memberExternalTransactionStatus,
    }
  },
})
</script>
